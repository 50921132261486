import React, { useState } from "react";
import { Form, Formik } from "formik";
import moment from "moment";
import * as Yup from "yup";
import classNames from "classnames";
import fileDownload from "js-file-download";

import Modal from "../../Templates/Modal";
import ExportDataColumns from "./ExportDataColumns";
import ExportDataParams from "./ExportDataParams";

import api from "../../../services/api";

const ValidationSchema = Yup.object().shape({
  to: Yup.date().required("This field is required"),
  from: Yup.date().max(
    Yup.ref("to"),
    "Please input an earlier date than the 'To' parameter"
  ),
});

const ExportDataModal = ({
  isModalActive,
  setIsModalActive,
  initialColumns,
  searchParams,
  selected,
  module,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleExportSubmit = async values => {
    const buildColumns = values.columnHeaders.join(",");
    const { from, to } = values;
    let params = {
      ...searchParams,
      from: from,
      to: to,
      columns: buildColumns,
    };
    try {
      setIsLoading(true);
      setHasError(false);
      const response = await api.get(
        `/consultations/exports/?${new URLSearchParams(params).toString()}`
      );
      fileDownload(response.data, `${module}-${values.from}-${values.to}.csv`);
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const buildDefaultInitialColumns = initialColumns
    .map(column => {
      if (column.default) return column.value;
    })
    .filter(item => item);

  const handleOnCheck = (event, data, setFieldValue, columnHeaders) => {
    if (event.target.checked && !isNaN(data.priority)) {
      columnHeaders.splice(data.priority, 0, data.value);
      setFieldValue("columnHeaders", columnHeaders);
    } else if (event.target.checked) {
      columnHeaders.push(data.value);
      setFieldValue("columnHeaders", columnHeaders);
    } else {
      setFieldValue(
        "columnHeaders",
        columnHeaders.filter(item => item !== data.value)
      );
    }
  };

  return (
    <Formik
      initialValues={{
        from: moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
        columnHeaders: buildDefaultInitialColumns,
      }}
      onSubmit={handleExportSubmit}
      validationSchema={ValidationSchema}
    >
      {({ values, setFieldValue, isValid }) => (
        <Form>
          <Modal
            color="primary"
            isOpen={isModalActive}
            heading={`Export Data (${
              selected.clinic.value
                ? selected.clinic.label
                : selected.client.label
            })`}
            close={() => setIsModalActive(false)}
          >
            <section className="modal-card-body">
              <ExportDataParams values={values} />
              <div className="mt-2 has-text-weight-bold is-size-5">
                Select columns to include
              </div>

              <ExportDataColumns
                columns={initialColumns}
                values={values}
                handleOnCheck={handleOnCheck}
                setFieldValue={setFieldValue}
              />
              {hasError && (
                <div className="message is-danger p-2 has-text-centered">
                  Error in exporting {module}. Please contact IT for more
                  information.
                </div>
              )}
            </section>
            <footer className="modal-card-foot">
              <button
                className={classNames("button is-primary", {
                  "is-loading": isLoading,
                })}
                type="submit"
                disabled={!isValid}
              >
                Export
              </button>
              <button
                type="button"
                className="button"
                onClick={() => setIsModalActive(false)}
              >
                Cancel
              </button>
            </footer>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default ExportDataModal;
