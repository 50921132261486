import React, { Fragment } from "react";
import { Field, ErrorMessage } from "formik";

const ExportDataParams = () => {
  return (
    <Fragment>
      <label className="label">Date Range (MM-DD-YYYY):</label>
      <div className="columns">
        <div className="column">
          <label>From</label>
          <Field name="from" type="date" className="input" />
          <div className="is-size-7 has-text-danger">
            <ErrorMessage name="from" />
          </div>
        </div>
        <div className="column">
          <label>To</label>
          <Field name="to" type="date" className="input" />
          <div className="is-size-7 has-text-danger">
            <ErrorMessage name="to" />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ExportDataParams;
