import React from "react";
import { Field } from "formik";

const ExportDataColumns = ({
  columns,
  handleOnCheck,
  values,
  setFieldValue,
}) => {
  const columnBreakdown = [
    { start: 0, end: Math.round(columns.length / 2) },
    {
      start: Math.round(columns.length / 2),
      end: columns.length,
    },
  ];

  return (
    <div className="columns">
      {columnBreakdown.map(({ start, end }) => (
        <div className="column">
          {columns.slice(start, end).map(column => (
            <div>
              <label>
                <Field
                  type="checkbox"
                  name="columnHeaders"
                  checked={values.columnHeaders.includes(column.value)}
                  onChange={event => {
                    handleOnCheck(
                      event,
                      column,
                      setFieldValue,
                      values.columnHeaders
                    );
                  }}
                />{" "}
                {column.alias || column.value}
              </label>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default ExportDataColumns;
