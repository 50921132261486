import React from "react";
import Layout from "../../../layout/Layout";
import AdminConsultations from "../../../components/Admin/Consultations/AdminConsultations";
import SEO from "../../../layout/Seo";

export default () => (
  <Layout pageTitle="Admin Consultations">
    <SEO title="Admin Consultations" />
    <AdminConsultations />
  </Layout>
);
