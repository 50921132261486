import React, { useState, Fragment } from "react";
import ExportDataModal from "./ExportDataModal";
import initialColumns from "./utils/initialColumns.json";

const ExportCSV = ({ label, module, searchParams, selected }) => {
  const [toggleModal, setToggleModal] = useState(false);
  return (
    <Fragment>
      <button
        className="button is-light is-medium mx-1"
        onClick={() => setToggleModal(!toggleModal)}
      >
        {label}
      </button>
      {toggleModal && (
        <ExportDataModal
          isModalActive={toggleModal}
          setIsModalActive={setToggleModal}
          initialColumns={initialColumns[module]}
          searchParams={searchParams}
          selected={selected}
          module={module}
        />
      )}
    </Fragment>
  );
};

export default ExportCSV;
