import React, { useEffect, useState, Fragment } from "react";
import api from "../../../services/api";
import Datalist from "../../Templates/Forms/Datalist";
// import AdminConsultationsTable from "./AdminConsultationsTable/AdminConsultationsTable";
// import Pagination from "../../Templates/Pagination";
import ConsultationLogs from "../../Consultation/ConsultationLogs/ConsultationLogs";
import ExportCSV from "../../../components/Dashboard/ExportDataModal/ExportCSV";

const initialClinics = [
  {
    label: "All Clinics",
    value: "",
  },
];

const initialClients = [
  {
    label: "All Clients",
    value: "all",
  },
];

const AdminConsultations = () => {
  const [options, setOptions] = useState({
    clients: initialClients,
    clinics: initialClinics,
  });
  const [selected, setSelected] = useState({
    client: options.clients[0],
    clinic: options.clinics[0],
  });
  const [searchParams, setSearchParams] = useState({});

  useEffect(() => {
    let params = {};
    if (selected.clinic.value) {
      params = { ...params, clinic: selected.clinic.value };
    } else if (selected.client.value) {
      params = { ...params, client: selected.client.value };
    }
    setSearchParams(params);
  }, [selected]);

  // This useEffect fetches the initial list of clients
  useEffect(() => {
    if (options.clients.length < 2)
      api.get(`clients/?is_active=True`).then(response => {
        let tempClients = initialClients;
        tempClients = tempClients.concat(
          response.data.results.map(item => ({
            label: item.name,
            value: item.code,
          }))
        );
        setOptions({ ...options, clients: tempClients });
      });
  }, [options.clients]);

  // This useEffect fetches the clinics list when the client dropdown value changes
  useEffect(() => {
    if (selected.client !== "all")
      api
        .get(`clinics/?client__code=${selected.client.value}&is_active=True`)
        .then(response => {
          let tempClinics = initialClinics;
          tempClinics = tempClinics.concat(
            response.data.results.map(item => ({
              label: item.name,
              value: item.id,
            }))
          );
          setOptions({ ...options, clinics: tempClinics });
        });
  }, [selected]);

  return (
    <Fragment>
      <div className="columns is-vcentered">
        <div className="column">
          <Datalist
            label="Client"
            hasLabel
            defaultValue={options.clients[0]}
            options={options.clients}
            value={selected.client}
            onChange={value => {
              setSelected({
                ...selected,
                client: value,
                clinic: initialClinics[0],
              });
            }}
          />
        </div>
        <div className="column">
          <Datalist
            label="Clinic"
            hasLabel
            defaultValue={options.clinics[0]}
            options={options.clinics}
            isDisabled={selected.client.value === "all"}
            value={selected.clinic}
            onChange={value => {
              setSelected({ ...selected, clinic: value });
            }}
          />
        </div>
        <div className="column">
          <div className="mt-3" />
          <ExportCSV
            label="Export Consultations"
            module="consultations"
            searchParams={searchParams}
            selected={selected}
          />
        </div>
      </div>
      <hr className="mt-1 mb-5" />
      <ConsultationLogs
        clientId={selected.client.value}
        clinicId={selected.clinic.value}
        isAdmin
      />
    </Fragment>
  );
};

export default AdminConsultations;
